import { FC, useEffect, useState, useRef, useMemo } from 'react';
import { Spin } from 'antd';
import { IRouteComponentProps, history } from 'umi';
import moment from 'moment';
import { useRequest } from 'ahooks';
import {
  StatusBox,
  Button,
  Input,
  Typography,
  TextArea,
  message,
} from 'iglooform';
import { userInfoState, projectState } from '@/store';
import { useRecoilValue } from 'recoil';
import { PlusOutlined, SearchOutlined, CrossOutlined } from 'iglooicon';
import {
  getProjectResource,
  saveEnglishResource,
  changeEnglishResourceContext,
  getProjectDetail,
} from '../../../service';
import BackTo from '@/components/back-to';
import Card from '@/components/card';
import Table from '@/components/table';
import styles from './index.less';

const statusConfig: { [key: string]: any } = {
  existing: 'failed',
  updated: 'success',
};

const EditEnglishResource: FC<IRouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const [editIndex, setEditIndex] = useState<any>(undefined);
  const [updatedAt, setUpdateTime] = useState(undefined);
  const tableBodyRef = useRef<any>(null);
  const fileInputRef = useRef<any>(null);
  const [noPermissionLoading, setNoPermissionLoading] = useState(false);
  const { email } = useRecoilValue(userInfoState);

  const [searchingWord, setSearchingWord] = useState('');
  const [tableResource, setTableResource] = useState<English[]>([]);

  const id = match.params.id;
  const buttons = [
    <Button
      icon={<PlusOutlined />}
      onClick={() => {
        //新的处理方式
        const newArr = [...tableResource];
        newArr.unshift({
          name: '',
          contextReference: '',
          status: 'updated',
        });
        setTableResource(newArr.map((res, index) => ({ ...res, key: index })));
      }}
    >
      Add Item
    </Button>,
    <Button
      onClick={() => {
        const ref = fileInputRef.current;
        ref.onchange = importFile;
        ref.click();
      }}
    >
      Import JSON File
    </Button>,
    <Button
      style={{ width: 140 }}
      onClick={() => {
        for (let index = 0; index < tableResource.length; index++) {
          const { name } = tableResource[index];
          if (name === '') {
            message.warning(
              `Please set the name of the English resource in ${
                index + 1
              }th line`,
            );
            return;
          }
        }
        saveResource(id, tableResource);
      }}
      type="primary"
    >
      Save
    </Button>,
  ];

  const buttonsWhenLocked = [
    <Button
      style={{ width: 140 }}
      onClick={() => {
        const changedResource = tableResource.map((re) => ({
          contextReference: re.contextReference,
          id: re.id,
        }));
        changeResourceContext(id, changedResource);
      }}
      type="primary"
    >
      Save
    </Button>,
  ];

  // 防止在此页面刷新找不到 recoil的缓存数据
  const { data } = useRequest<Project>(getProjectDetail, {
    defaultParams: [id],
    onSuccess: (res) => {
      const { frontEngineers = [], productManagers = [], status = '' } = res;
      if (
        !['ACTIVE', 'LOCKED'].includes(status) ||
        ![...frontEngineers, ...productManagers].includes(email)
      ) {
        setNoPermissionLoading(true);

        message.warning(
          !['ACTIVE', 'LOCKED'].includes(status)
            ? `English resource can't be edit in current status`
            : "You don't have the permission of this page,will return back",
          1.5,
        );
        setTimeout(() => {
          setNoPermissionLoading(false);
          history.replace(`/projects/${id}/detail`);
        }, 1500);
      }
    },
  });

  const { status } = data || {};

  const { loading, run: refreshList } = useRequest(getProjectResource, {
    onSuccess: (res) => {
      const { data, updatedAt } = res;
      const addStatus = (data || []).map((eng: any, index: number) => ({
        ...eng,
        status: 'existing',
        key: index,
      }));
      setTableResource(addStatus);
      setUpdateTime(updatedAt);
    },
    defaultParams: [id],
  });

  const { loading: saveLoading, run: saveResource } = useRequest(
    saveEnglishResource,
    {
      manual: true,
      onSuccess: () => {
        message.success('English resource saved successfully');
        refreshList(id);
      },
      onError: () => {
        refreshList(id);
      },
    },
  );

  const { loading: changeContextLoading, run: changeResourceContext } =
    useRequest(changeEnglishResourceContext, {
      manual: true,
      onSuccess: () => {
        message.success('English context reference saved successfully');
        refreshList(id);
      },
    });

  useEffect(() => {
    if (editIndex === undefined) return;

    function closeEdit(e: any) {
      if (!tableBodyRef.current) return;

      if (!tableBodyRef.current.contains(e.target)) {
        setEditIndex(undefined);
      }
    }

    window.addEventListener('click', closeEdit);

    return () => {
      window.removeEventListener('click', closeEdit);
    };
  }, [editIndex]);

  function importFile() {
    const input = fileInputRef.current;
    const file = input.files[0];
    const { type } = file;
    if (!type.includes('json')) {
      message.error('Only support json file');
      return;
    }
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onerror = function () {
      message.error('Please check file content');
    };
    reader.onload = function (e) {
      const result = e.target?.result as string;
      const jsonKeys = Object.keys(JSON.parse(result));
      const newResource: English[] = jsonKeys.map((key) => ({
        name: key,
        contextReference: '',
        status: 'updated',
      }));
      const oldResourceSet = {};
      tableResource.forEach(({ name, contextReference }) => {
        oldResourceSet[name] = contextReference;
      });

      setTableResource(
        newResource.map((res, index) => ({
          ...res,
          key: index,
          contextReference: oldResourceSet[res.name],
          status: oldResourceSet[res.name] ? 'existing' : 'updated',
        })),
      );
    };
    // 重置input的value才能让下次点击同一个文件地址的时候触发onchange事件
    fileInputRef.current.value = null;
  }

  const columns = [
    {
      title: 'No',
      dataIndex: 'id',
      render: (id: number, row: any, index: number) => index + 1,
    },
    {
      title: 'Item(EN)',
      dataIndex: 'name',
      className: styles.tableColumn,
      render: (itemName: string, { key }: any) => {
        return key === editIndex && status === 'ACTIVE' ? (
          <TextArea
            defaultValue={itemName}
            onBlur={(e) => {
              tableResource[key].name = e.target.value;
              setTableResource([...tableResource]);
            }}
          />
        ) : (
          itemName
        );
      },
    },
    {
      title: 'Context Reference',
      dataIndex: 'contextReference',
      render: (context: string, { key }: any) => {
        return key === editIndex ? (
          <TextArea
            defaultValue={context}
            onBlur={(e) => {
              tableResource[key].contextReference = e.target.value;
              setTableResource([...tableResource]);
            }}
          />
        ) : (
          context
        );
      },
    },
    {
      title: 'Item Status',
      dataIndex: 'status',
      render: (text: string) => (
        <StatusBox type={statusConfig[text]}>{text}</StatusBox>
      ),
    },
  ];

  const actionCol = {
    title: 'Action',
    dataIndex: 'action',
    render: (_: string, { key }: any) => {
      return (
        <span onClick={(e) => e.stopPropagation()}>
          <CrossOutlined
            style={{ color: '#666666', fontSize: 24 }}
            onClick={() => {
              setEditIndex(undefined);
              tableResource.splice(key, 1);
              setTableResource(
                [...tableResource].map((res, index) => ({
                  ...res,
                  key: index,
                })),
              );
            }}
          />
        </span>
      );
    },
  };

  const handleFilter = (keyword: string) => {
    setSearchingWord(keyword);
  };

  return (
    <Spin spinning={saveLoading || noPermissionLoading}>
      <div className={styles.editContainer}>
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: 'none' }}
        ></input>
        <BackTo
          targetName="Project Info"
          targetPath={`/projects/${id}/detail`}
        />
        <Card
          title="English Resource"
          extraButtons={status === 'ACTIVE' ? buttons : buttonsWhenLocked}
        >
          <div className={styles.top}>
            <Input
              style={{ margin: '24px 0px 0px 0px' }}
              className={styles.topInput}
              prefix={
                <SearchOutlined style={{ fontSize: 24, color: '#666666' }} />
              }
              onPressEnter={(e) => handleFilter((e.target as any).value)}
            />

            {updatedAt && (
              <Typography level="body2" className={styles.updatedAt}>
                Last updated at:
                {moment(updatedAt).format('DD/MM/YYYY HH:mm:ss')}
              </Typography>
            )}
          </div>
          <Table
            loading={loading || changeContextLoading}
            className={styles.table}
            dataSource={
              searchingWord
                ? tableResource.filter(
                    ({ name }) =>
                      name === '' ||
                      name.toLowerCase().includes(searchingWord.toLowerCase()),
                  )
                : tableResource
            }
            columns={status === 'ACTIVE' ? columns.concat(actionCol) : columns}
            components={{
              body: {
                wrapper: (children: any) => {
                  return <tbody ref={tableBodyRef} {...children}></tbody>;
                },
              },
            }}
            onClickRow={({ key }) => {
              setEditIndex(key);
            }}
            rowKey="id"
          />
        </Card>
      </div>
    </Spin>
  );
};

export default EditEnglishResource;
