import { FC } from 'react';
import { Table } from 'iglooform';
import { ITableProps } from 'iglooform/es/table';
import classNames from 'classnames';
import styles from './index.less';

const InnerTable: FC<
  Omit<ITableProps, 'onPagination' | 'page' | 'pageSize' | 'total'>
> = (props) => {
  return (
    <Table
      {...props}
      className={classNames(props.className, styles.tableForTranslation)}
      onPagination={() => {}}
      page={0}
      pageSize={0}
      total={0}
      noCustomize
      noPagination
    />
  );
};

export default InnerTable;
