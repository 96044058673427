import { FC, useEffect } from 'react';
import { Layout } from 'antd';
import HeadBar from './header';
import classNames from 'classnames';
import { RecoilRoot, useRecoilState, useSetRecoilState } from 'recoil';
import {
  languagesState,
  tokenState,
  userInfoState,
  pmState,
  feState,
} from '@/store';
import { history, useLocation } from 'umi';
import useUrlState from '@ahooksjs/use-url-state';
import { useRequest } from 'ahooks';
import { message } from 'iglooform';
import { updateRequest } from '@/utils/request';
import { getEmail } from '@/utils/jwt';

import styles from './index.less';
import { getPermissions, getLanguages, getIglooUser } from '@/services';

const handleLogout = () => {
  localStorage.removeItem(TOKEN_NAME);
  window.location.href = `${SSO_URL}/logout?app=${window.location.origin}`;
};

const BasicLayout: FC = ({ children }) => {
  const [token, setToken] = useRecoilState(tokenState);
  const setUserInfo = useSetRecoilState(userInfoState);
  const setLanguages = useSetRecoilState(languagesState);
  const setPM = useSetRecoilState(pmState);
  const setFE = useSetRecoilState(feState);
  const [query] = useUrlState();

  const { run: initPermissions } = useRequest(getPermissions, {
    manual: true,
    onSuccess: (data) => {
      if (!data?.permissions?.length) {
        history.push('/403');
        return;
      }
      setUserInfo({
        permissions: data.permissions.map(({ key }: any) => key),
        email: getEmail(),
      } as any);
    },
    onError() {
      message.error('Fetch permissions failed.');
      handleLogout();
    },
  });

  const { run: initLanguages } = useRequest(getLanguages, {
    manual: true,
    onSuccess: ({ data }) => {
      setLanguages(data);
    },
  });

  const { run: getPMList } = useRequest(getIglooUser, {
    manual: true,
    defaultParams: ['PM'],
    onSuccess: ({ data }) => {
      setPM(data?.map(({ email }: any) => email));
    },
  });

  const { run: getFEList } = useRequest(getIglooUser, {
    manual: true,
    defaultParams: ['Developer'],
    onSuccess: ({ data }) => {
      setFE(data?.map(({ email }: any) => email));
    },
  });

  const location = useLocation();
  const pathName = location?.pathname;

  if (query.token || !token) {
    localStorage.setItem(TOKEN_NAME, query.token);
    setToken(query.token);
    updateRequest();
  }

  useEffect(() => {
    if (!token) {
      return;
    }

    initPermissions();
    initLanguages();
    getFEList('Developer');
    getPMList('PM');
  }, [token]);

  if (!token && !query.token) {
    window.location.href = `${SSO_URL}/login?app=${window.location.href}`;
    return null;
  }

  return (
    <Layout className={styles.layout}>
      {pathName !== '/403' && (
        <Layout.Header className={styles.header}>
          <HeadBar pathName={pathName} />
        </Layout.Header>
      )}
      <Layout.Content
        className={classNames({
          [styles.content]: true,
          [styles.noPadding]:
            pathName.includes('detail') || pathName.includes('multi-lingual'),
          [styles.noHeader]: pathName === '/403',
        })}
      >
        {children}
      </Layout.Content>
    </Layout>
  );
};

export default ({ children }: any) => (
  <RecoilRoot>
    <BasicLayout>{children}</BasicLayout>
  </RecoilRoot>
);
