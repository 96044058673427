import { useState, useEffect } from 'react';
import { ChatCompletionRequestMessage, Configuration, OpenAIApi } from 'openai';

const configuration = new Configuration({
  apiKey: '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',
});

const openai = new OpenAIApi(configuration);

export const useAITranslation = (
  sentences: string[],
  targetLanguage: string,
) => {
  const [results, setResults] = useState<{ [key: string]: string[] }>({});
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [done, setDone] = useState(0);

  useEffect(() => {
    const doAITranslation = async () => {
      const sentencesSlice = [];
      const sentencesToGPT = sentences.filter((s) => !results[s]);

      for (let i = 0; i < sentencesToGPT.length; i += 20) {
        const _sentences = sentencesToGPT.slice(i, i + 20);

        sentencesSlice.push(_sentences);
      }

      setTotal(sentencesSlice.length);

      try {
        await Promise.allSettled(
          sentencesSlice.map((sentences) =>
            openai
              .createChatCompletion(
                {
                  model: 'gpt-4',
                  messages: [
                    {
                      role: 'system',
                      content: `Please translate the following sentences which are from insurance indurstry into ${targetLanguage}, and do not translate words inside {}.`,
                    },
                    {
                      role: 'system',
                      content: `You should provide 2-3 options for each sentence, and return a key-value object (key is the original sentence, and value is an array of tranlation options). Make sure you turen a key-value object.`,
                    },
                    {
                      role: 'system',
                      content: `You should make the keys exactly the same with origin sentences especially if there's order number at the begaining of the sentences.`,
                    },
                    ...sentences.map(
                      (s) =>
                        ({
                          role: 'system',
                          content: s,
                        } as ChatCompletionRequestMessage),
                    ),
                  ],
                },
                {
                  timeout: 3600000,
                },
              )
              .then((completion) => {
                if (completion.data.choices[0].message?.content) {
                  const _results = JSON.parse(
                    completion.data.choices[0].message.content,
                  );

                  setResults((results) => ({ ...results, ..._results }));
                }
              })
              .finally(() => {
                setDone((done) => done + 1);
              }),
          ),
        );
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);
    setTotal(0);
    setDone(0);
    doAITranslation();
  }, [sentences]);

  return { results, loading, total, done };
};
