import { RequestConfig, history } from 'umi';
import { decamelizeKeys, camelizeKeys } from 'humps';
import { message } from 'iglooform';

/**
 * Decamelize Request Params Keys
 * @param url
 * @param options
 */
const decamelizeParams = (url: string, options: any) => {
  const { params, data } = options;

  return {
    url,
    options: {
      ...options,
      params: decamelizeKeys(params),
      data: decamelizeKeys(data),
    },
  };
};

/**
 * Camelize Response Data Keys
 * @param response
 */
const camelizeData = async (response: any) => {
  const { status } = response;
  let json;
  try {
    json = await response.json();
  } catch (e) {
    //@ts-ignore
    console.warn(e);
    json = {};
  }

  switch (status) {
    case 200:
      return camelizeKeys(json);
    case 401: {
      localStorage.removeItem(TOKEN_NAME);
      window.location.href = `${SSO_URL}/logout?app=${
        window.location.origin
      }&lastToken=${localStorage.getItem(TOKEN_NAME)}`;
      return Promise.reject({
        status,
      });
    }
    default:
      return Promise.reject({
        status,
        ...json,
      });
  }
};

export const request: RequestConfig = {
  prefix: API_URL,
  headers: {
    'X-Axinan-Authorization': localStorage.getItem(TOKEN_NAME) as string,
  },
  errorHandler: (error: any) => {
    message.error(error.error);
    throw error;
  },
  requestInterceptors: [decamelizeParams],
  //@ts-ignore
  responseInterceptors: [camelizeData],
};
