import { FC, useEffect, useMemo, useState } from 'react';
import { Spin, Radio } from 'antd';
import { IRouteComponentProps, history } from 'umi';
import { useRequest } from 'ahooks';
import {
  StatusBox,
  message,
  Button,
  FreeForm,
  FormPage,
  FormItem,
  Form,
  TextArea,
  Typography,
} from 'iglooform';
import {
  getMultiLingualResource,
  saveMultiLingualResource,
} from '../../../service';
import { userInfoState } from '@/store';
import { useRecoilValue } from 'recoil';
import BackTo from '@/components/back-to';
import Card from '@/components/card';
import { useAITranslation } from '@/utils/gpt';
import styles from './index.less';

interface Lang {
  contentReference: string;
  id: number;
  lastTranslatedAt: string;
  lastTranslator: string;
  name: string;
  target: string;
}

const ResourceStatus: { [key: string]: any } = {
  ACTIVE: 'warning',
  TRANSLATING: 'waiting',
  REVIEWING: 'warning',
  READY: 'success',
};

const AITranslation: FC<
  IRouteComponentProps<{ id: string; language: string }>
> = ({ match }) => {
  const userInfo = useRecoilValue(userInfoState);
  const [langResource, setResource] = useState<Lang[]>([]);
  // const [aiResults, setAIResults] = useState<{
  //   success: boolean;
  //   results: { [key: string]: string[] };
  // }>({ success: false, results: {} });
  const [resourceStatus, setStatus] = useState('');
  const form = Form.useForm();

  const projectId = match.params.id;
  const lang = match.params.language;

  const { loading, run: refreshList } = useRequest<{
    status: string;
    resources: Lang[];
    translatorEmail: string;
  }>(getMultiLingualResource, {
    onSuccess: (res) => {
      const { resources, status = '', translatorEmail } = res;
      setStatus(status);
      setResource(resources.filter((r) => !r.target));
    },
    defaultParams: [projectId, lang],
  });

  const sentences = useMemo(() => {
    return langResource.map(({ name }) => name.trim());
  }, [langResource]);

  const {
    results: aiResults,
    loading: aiLoading,
    total,
    done,
  } = useAITranslation(sentences, lang);

  const { loading: saveLoading, run: saveResource } = useRequest(
    saveMultiLingualResource,
    {
      manual: true,
      onSuccess: () => {
        message.success(`${lang} resource saved successfully`);
        history.push(`/projects/${projectId}/multi-lingual`);
      },
    },
  );

  return (
    <Spin
      spinning={saveLoading || aiLoading}
      tip={
        <Typography style={{ marginTop: 16 }} level="h2" wrapElement="div">
          {done} parts are translated. {total - done} parts are still under
          translating...
        </Typography>
      }
    >
      <div className={styles.editContainer}>
        <BackTo
          targetName="Multi-lingual Resource"
          targetPath={`/projects/${projectId}/multi-lingual`}
        />
        <Card
          title={`Translation results from AI`}
          pageStatusIcon={
            <StatusBox type={ResourceStatus[resourceStatus]}>
              {resourceStatus}
            </StatusBox>
          }
          extraButtons={[
            <Button
              onClick={() => setResource((resources) => Array.from(resources))}
              disabled={sentences.every((s) => !!aiResults[s])}
            >
              Re-Generate
            </Button>,
          ]}
        >
          <FreeForm
            form={form}
            onSubmit={(results) =>
              saveResource(
                projectId,
                lang,
                Object.entries(results)
                  .filter(([name]) => name)
                  .map(([name, target]) => {
                    const originalSource =
                      langResource.find(
                        ({ name: origiName }) => origiName === name,
                      ) || {};

                    return {
                      ...originalSource,
                      name,
                      target,
                    };
                  }),
              )
            }
          >
            <FormPage>
              {langResource.map(({ name }) => {
                const en = name.trim();
                const options = Array.from(new Set(aiResults[en] || []));

                return (
                  <FormItem
                    label={en}
                    name={name}
                    initialValue={options.length === 1 ? options[0] : undefined}
                    fullRow
                    required={false}
                  >
                    <Options options={options} />
                  </FormItem>
                );
              })}
            </FormPage>
          </FreeForm>
        </Card>
      </div>
    </Spin>
  );
};

export default AITranslation;

const Options: FC<{
  options: string[];
  value?: string;
  onChange?: (value: string) => any;
}> = ({ options, value, onChange = () => {} }) => {
  const [innerOptions, setOptions] = useState(options);

  useEffect(() => {
    setOptions(options);
  }, [options]);

  return (
    <div className={styles.optionContainer}>
      {innerOptions.map((option, index) => (
        <div key={option} className={styles.option}>
          <Radio
            checked={option === value}
            onChange={(e) => e.target.checked && onChange(option)}
          />
          {value === option ? (
            <TextArea
              onBlur={(e) => {
                const v = e.target.value;

                setOptions((options) => {
                  const newOptions = [...options];
                  newOptions[index] = v;

                  return newOptions;
                });
                onChange(v);
              }}
              defaultValue={option}
            />
          ) : (
            option
          )}
        </div>
      ))}
    </div>
  );
};
