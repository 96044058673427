import { FC, memo } from 'react';
import { ArrowLeftOutlined } from 'iglooicon';
import { Button } from 'iglooform';
import { history } from 'umi';

interface BackToProps {
  targetName: string;
  targetPath: string;
}

const BackTo: FC<BackToProps> = ({ targetName, targetPath }) => {
  return (
    <Button
      type="link"
      onClick={() => history.push(targetPath)}
      style={{ marginBottom: 40 }}
    >
      <ArrowLeftOutlined style={{ marginRight: 2 }} /> Back to {targetName}
    </Button>
  );
};

export default BackTo;
