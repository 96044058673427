import { Button } from 'antd';
import forbidden from '@/assets/403.svg';

export default () => {
  const handleLogout = () => {
    localStorage.removeItem(TOKEN_NAME);
    window.location.href = `${SSO_URL}/logout?app=${window.location.origin}`;
  };

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <div style={{ margin: '240px auto', textAlign: 'center' }}>
        <img src={forbidden} style={{ width: 84, height: 100 }} />
        <div
          style={{
            fontSize: 30,
            color: '#212121',
            fontWeight: 500,
            marginTop: 48,
          }}
        >
          403 Forbidden
        </div>
        <div style={{ fontSize: 20, color: '#424242', marginTop: 16 }}>
          You do not have the access to LINA. Please contact the administrator
          to apply for a permission.
        </div>
        <Button
          type="default"
          style={{ width: 200, marginTop: 24 }}
          onClick={handleLogout}
        >
          Log Out
        </Button>
      </div>
    </div>
  );
};
