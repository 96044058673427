import { atom, selector } from 'recoil';
import { getEmail } from '@/utils/jwt';
import { request } from 'umi';

export const tokenState = atom<string | null>({
  key: 'token',
  default: localStorage.getItem(TOKEN_NAME),
});

export const englishResState = atom<{
  lastUpdatedAt?: number;
  res: English[];
}>({
  key: 'englishRes',
  default: {} as any,
});

export const otherLang = atom<{
  [key: string]: MultiLingualResources;
}>({
  key: 'otherLangState',
  default: {},
});

export const otherLangResState = selector<{
  [key: string]: MultiLingualResources;
}>({
  key: 'otherLang',
  get: ({ get }) => get(otherLang),
  set: ({ get, set }, newValue) => {
    const otherLangRes = get(otherLang);
    set(otherLang, {
      ...otherLangRes,
      ...newValue,
    });
  },
});

export const projectState = atom<Project>({
  key: 'project',
  default: {} as Project,
});

const userState = atom<{
  email: string;
  permissions: Permission[];
}>({
  key: 'userInfoInner',
  default: {
    email: getEmail(),
    permissions: [],
  },
});

export const userInfoState = selector<{
  email: string;
  permissions: Permission[];
}>({
  key: 'userInfo',
  get: ({ get }) => get(userState),
  set: ({ get, set }, newValue) => {
    const userInfo = get(userState);

    set(userState, { ...userInfo, ...newValue });
  },
});

export const languagesState = atom<Language[]>({
  key: 'languages',
  default: [],
});

export const pmState = atom<string[]>({
  key: 'pm',
  default: [],
});

export const feState = atom<string[]>({
  key: 'fe',
  default: [],
});

export const translatorState = atom<{ [key: string]: string[] }>({
  key: 'translator',
  default: {},
});

export const reviewerState = atom<{ [key: string]: string[] }>({
  key: 'reviewer',
  default: {},
});
