import { request } from '@/utils/request';

export const getProjects = (params: any) => {
  return request('/v1/multilingual/projects', {
    params,
  });
};

export const getProjectDetail = (id: any) =>
  request(`/v1/multilingual/projects/${id}`);

export const getProjectResource = (id: any) =>
  request(`/v1/multilingual/projects/${id}/resource`);

export const getMultiLingualResource = (projectId: any, lang: any) =>
  request(`/v1/multilingual/projects/${projectId}/translation/${lang}`);

export const syncMultiLingualResource = (projectId: any, lang: any) =>
  request(
    `/v1/multilingual/projects/${projectId}/translation/${lang}/refresh`,
    {
      method: 'put',
    },
  );

export const saveEnglishResource = (id: string, data: any) => {
  return request(`/v1/multilingual/projects/${id}/resource`, {
    method: 'put',
    data: {
      data,
    },
  });
};

export const changeEnglishResourceContext = (id: string, data: any) => {
  return request(`/v1/multilingual/projects/${id}/resource/context_reference`, {
    method: 'put',
    data: {
      data,
    },
  });
};

export const saveMultiLingualResource = (
  projectId: any,
  lang: string,
  resources: any,
) => {
  return request(`/v1/multilingual/projects/${projectId}/translation/${lang}`, {
    method: 'put',
    data: {
      resources,
    },
  });
};

export const startTranslation = (projectId: any, languages: any) =>
  request(`/v1/multilingual/projects/${projectId}/start_translate`, {
    method: 'put',
    data: {
      languages,
    },
  });

export const startReview = (projectId: any) =>
  request(`/v1/multilingual/projects/${projectId}/start_review`, {
    method: 'put',
  });

export const active = (projectId: any) =>
  request(`/v1/multilingual/projects/${projectId}/active`, {
    method: 'put',
  });

export const complete = (projectId: any, lang: string) =>
  request(
    `/v1/multilingual/projects/${projectId}/translation/${lang}/complete`,
    {
      method: 'put',
    },
  );

export const approve = (projectId: any, lang: string) =>
  request(
    `/v1/multilingual/projects/${projectId}/translation/${lang}/review_approve`,
    {
      method: 'put',
    },
  );

export const reject = (projectId: any, lang: string, data: any) =>
  request(
    `/v1/multilingual/projects/${projectId}/translation/${lang}/review_reject`,
    {
      method: 'put',
      data,
    },
  );

export const reassign = (projectId: any, lang: string, data: any) =>
  request(
    `/v1/multilingual/projects/${projectId}/translation/${lang}/reassign`,
    {
      method: 'put',
      data,
    },
  );
