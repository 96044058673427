import { FC } from 'react';
import { Dropdown } from 'antd';
import { ArrowDownOutlined, AccountFilled } from 'iglooicon';
import { getEmail } from '@/utils/jwt';
import { history } from 'umi';
import styles from './index.less';
import { useState } from 'react';
import classNames from 'classnames';
import { useEffect } from 'react';

const topMenus = [
  {
    title: 'projects',
    active: 'projects',
    key: 'projects',
  },
  {
    title: 'alphabet',
    active: 'alphabet',
    key: 'alphabet',
  },
];

const Header: FC<{ pathName?: string }> = ({ pathName }) => {
  const email = getEmail();

  const [activeKey, setActiveKey] = useState<string>('projects');

  useEffect(() => {
    const path = pathName?.split('/')[1];
    path && setActiveKey(path);
  }, [pathName]);

  const logout = () => {
    localStorage.removeItem(TOKEN_NAME);
    window.location.href = `${SSO_URL}/logout?app=${window.location.origin}`;
  };

  return (
    <div className={styles.headerContent}>
      <div className={styles.leftBox}>
        <div
          className={styles.logoBox}
          onClick={() => history.push('/projects')}
        >
          <img
            src="https://static.iglooinsure.com/icons/favicon.ico"
            className={styles.logo}
          />
          LINA
        </div>
        <div className={styles.divider}></div>
        {topMenus.map(({ title, active, key }) => {
          return (
            <div
              className={classNames(styles.topButton, {
                [styles.topButtonActive]: activeKey === active,
              })}
              onClick={() => history.push('/' + title)}
              key={key}
            >
              {title.charAt(0).toUpperCase() + title.slice(1)}
            </div>
          );
        })}
      </div>
      <div className={styles.rightBox}>
        <Dropdown
          overlay={
            <div className={styles.popContent}>
              <div className={styles.opItem} onClick={logout}>
                Log Out
              </div>
            </div>
          }
          trigger={['click']}
          getPopupContainer={(trigger) =>
            trigger.parentElement || document.body
          }
        >
          <div className={styles.dropdown}>
            <AccountFilled className={styles.accountIcon} />
            <span className={styles.email}>{email}</span>
            <ArrowDownOutlined className={styles.arrowIcon} />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default Header;
