import { useEffect, useState } from 'react';
import { Table, Typography, StatusBox } from 'iglooform';
import { PlusOutlined } from 'iglooicon';
import { useRequest } from 'ahooks';
import { ITableProps } from 'iglooform/es/table';
import { userInfoState } from '@/store';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import useProjectModal from '@/components/project-modal';
import { getProjects } from './service';
import { history } from 'umi';

const ProjectStatus: { [key: string]: any } = {
  ACTIVE: 'warning',
  LOCKED: 'waiting',
  READY: 'success',
};

const statusList = ['READY', 'ACTIVE', 'LOCKED'];

const sortType: { [key: string]: any } = {
  ascend: 'asc',
  descend: 'desc',
};
export default () => {
  const { email, permissions } = useRecoilValue(userInfoState);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [listParams, setListParams] = useState<{ [key: string]: any }>({
    page_no: 1,
    page_size: 10,
  });

  const { loading, run: getProjectList } = useRequest<any>(getProjects, {
    manual: true,
    onSuccess: (res) => {
      setList(res.data);
      setTotal(res.total);
    },
  });

  useEffect(() => {
    if (!permissions.length) return;

    const isAdmin = permissions.includes('Admin');
    const newParams = isAdmin
      ? {
          page_no: 1,
          page_size: 10,
        }
      : {
          page_no: 1,
          page_size: 10,
          email,
        };

    setListParams(newParams);
    getProjectList(newParams);
  }, [email, permissions]);

  const showModal = useProjectModal({ onSuccess: getProjectList });

  const columns = [
    {
      title: 'Project Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Target Language',
      dataIndex: 'languages',
      key: 'languages',
      render: (languages: string[]) => {
        return languages?.join(', ');
      },
    },
    {
      title: "Project's PM",
      dataIndex: 'productManagers',
      key: 'product_managers',
      render: (managers: string[]) => {
        return managers?.join(', ');
      },
    },
    {
      title: "Project's FE",
      dataIndex: 'frontEngineers',
      key: 'front_engineers',
      render: (engineers: string[]) => {
        return engineers?.join(', ');
      },
    },
    {
      title: 'Project Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <StatusBox type={ProjectStatus[status]}>
          {status.toUpperCase()}
        </StatusBox>
      ),
    },
    {
      title: 'Status Update',
      dataIndex: 'statusUpdatedAt',
      key: 'status_updated_at',
      sorter: true,
      render: (time: number) =>
        time ? moment(time).format('DD/MM/YYYY HH:mm') : '-',
    },
  ];

  const handlePagination = (page: number, pageSize: number) => {
    const newPageSize = listParams.page_size !== pageSize;

    const newParams = {
      ...listParams,
      page_no: newPageSize ? 1 : page,
      page_size: pageSize,
    };
    getProjectList(newParams);
    setListParams(newParams);
  };

  const handleSort: ITableProps['onChange'] = (_, __, sorter: any, extra) => {
    const newParams: { [key: string]: any } = {
      ...listParams,
    };
    if (extra.action === 'sort') {
      newParams.sort_by = sorter.columnKey;
      newParams.sort_type = sortType[sorter.order];
    } else {
      newParams.sort_by = undefined;
      newParams.sort_type = undefined;
    }
    getProjectList(newParams);
    setListParams(newParams);
  };

  const button = [
    {
      text: 'Create Project',
      icon: <PlusOutlined />,
      onClick: showModal,
    },
  ];

  const enterDetail = (record: Project) => {
    history.push(`/projects/${record.id}/detail`);
  };

  return (
    <div>
      <Typography level="h2"> Project List </Typography>

      <Table
        loading={loading}
        style={{ marginTop: 32 }}
        filterConfig={{
          idKeyTypeDefault: 'name',
          filterTitle: 'Filter Project List',
          onFilter: (params) => {
            const newParams: { [key: string]: any } = {
              ...listParams,
              page_no: 1,
            };
            if (params.status) {
              newParams.status = params.status.join(',');
            } else {
              newParams.status = undefined;
            }

            if (params.name) {
              newParams.search_by = 'name';
              newParams.search_keyword = params.name;
            } else {
              newParams.search_by = undefined;
              newParams.search_keyword = undefined;
            }
            getProjectList(newParams);
            setListParams({ ...newParams });
          },
          filterItems: [
            {
              type: 'mult',
              key: 'status',
              filterItemTitle: 'status',
              options: statusList.map((status) => ({
                label: status,
                value: status,
              })),
            },
          ],
        }}
        rowKey={'id'}
        columns={columns}
        dataSource={list}
        total={total}
        onChange={handleSort}
        page={listParams.page_no}
        pageSize={listParams.page_size}
        noCustomize={true}
        onPagination={(page, pageSize = 10) => handlePagination(page, pageSize)}
        extraButtons={permissions.includes('CreateProject') ? button : []}
        onClickRow={enterDetail}
      ></Table>
    </div>
  );
};
