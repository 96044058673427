import { FC } from 'react';
import { CardPage } from 'iglooform';
import { ICardPageProps } from 'iglooform/es/card-detail/card-page';
import classNames from 'classnames';
import styles from './index.less';

const Card: FC<ICardPageProps> = (props: any) => {
  return (
    <CardPage
      {...props}
      className={classNames(styles.multiLingualCard, props.className)}
    />
  );
};

export default Card;
