import { request } from '@/utils/request';

export const getPermissions = () =>
  request('/v1/admin_account/account/platform/Translation/permissions');

export const getLanguages = () => request('/v1/multilingual/languages');

export const getIglooUser = (roleKey: string) => {
  return request(`/v1/admin_account/account/platform/Translation/accounts`, {
    params: {
      role_key: roleKey,
    },
  });
};

export const createProject = (params: any) =>
  request('/v1/multilingual/projects', {
    method: 'post',
    data: params,
  });

export const updateProject = (id: number, params: any) =>
  request(`/v1/multilingual/projects/${id}`, {
    method: 'put',
    data: params,
  });
