// https://www.figma.com/file/Ybv57FdMp0Xkg7CzvyPj1x/Igloo-Multi-lingual-Translation-Platform?node-id=45%3A3373
import { FC, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { languagesState } from '@/store';
import { useRequest } from 'ahooks';
import styles from './index.less';
import { getAlphabets } from './services';
import { message, Table, Typography } from 'iglooform';
import { Input } from 'antd';
import { SearchOutlined } from 'iglooicon';
import { useState } from 'react';
import { useMemo } from 'react';

const Alphabet: FC = () => {
  const languages = useRecoilValue(languagesState);
  const [tableList, setTableList] = useState([]);
  const [page_no, setPage] = useState<number>(1);
  const [page_size, setPageSize] = useState<number>(10);
  const [search_by, setSearchBy] = useState<string | undefined>(undefined);
  const [search_keyword, setSearchKey] = useState<string | undefined>(
    undefined,
  );
  const [total, setTotal] = useState(0);

  const { run: getAlphabetsLang, loading } = useRequest(getAlphabets, {
    manual: true,
    onSuccess: (res) => {
      setTotal(res.total);
      setTableList(res.data);
    },
    onError() {
      message.error('Fetch Alphabets failed.');
    },
    // defaultParams: [{page_no, page_size, search_by}]
  });

  const columnsAll = useMemo(() => {
    if (!languages.length) {
      return [];
    }
    const _columns: {
      title: string;
      dataIndex: string | string[];
      key: string;
    }[] = [
      {
        title: 'en-US',
        dataIndex: [`name`],
        key: 'en-US',
      },
    ];

    languages.forEach((data) => {
      const keyword = data.name.replace('-', '');
      _columns.push({
        title: data.name,
        dataIndex: [`target`, keyword],
        key: data.name,
      });
    });
    return _columns;
  }, [languages]);

  useEffect(() => {
    if (languages.length) {
      getAlphabetsLang({ page_no, page_size, search_keyword, search_by });
    }
  }, [page_no, page_size, search_keyword, search_by, languages]);

  const handleSearch = (value: string) => {
    setPage(1);
    if (value === '') {
      setSearchKey(undefined);
      return;
    }
    setSearchKey(value);
  };

  const handlePagination = (newPage: number, newPageSize: number) => {
    setPage(newPageSize === page_size ? newPage : 1);
    setPageSize(newPageSize);
  };

  return (
    <div>
      <Typography level="h2">Aphabet</Typography>
      <div className={styles.searchBox}>
        <Input
          className={styles.searchInput}
          prefix={<SearchOutlined className={styles.icons} />}
          onPressEnter={(e) => {
            const element = e.target as HTMLInputElement;
            handleSearch(element.value);
          }}
          onBlur={(e) => handleSearch(e.target.value)}
        />
      </div>
      {columnsAll.length ? (
        <Table
          style={{ marginBottom: 30 }}
          total={total}
          sticky={true}
          rowKey={'id'}
          columns={columnsAll}
          dataSource={tableList}
          noCustomize
          page={page_no}
          pageSize={page_size}
          onPagination={(page, pageSize = 10) =>
            handlePagination(page, pageSize)
          }
          loading={loading}
        />
      ) : null}
    </div>
  );
};

export default Alphabet;
