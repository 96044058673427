import { useCallback, useRef } from 'react';
import { Modal } from 'iglooform';
import { languagesState, feState, pmState, userInfoState } from '@/store';
import { useRecoilValue } from 'recoil';
import { createProject, updateProject } from '@/services';
import styles from './index.less';

interface modalProps {
  projectDetail?: Project;
  onSuccess?: Function;
}

export default function useProjectModal(props: modalProps) {
  const { projectDetail, onSuccess } = props;
  const languageList = useRecoilValue(languagesState);
  const feList = useRecoilValue(feState);
  const pmList = useRecoilValue(pmState);
  const userInfo = useRecoilValue(userInfoState);

  const ref = useRef(projectDetail);

  const onOk = async (values: any) => {
    projectDetail?.id
      ? await updateProject(projectDetail?.id as number, values)
      : await createProject(values);

    typeof onSuccess === 'function' && onSuccess();
  };

  const showModal = useCallback(() => {
    ref.current = projectDetail;
    Modal.confirm({
      className: styles.modal,
      closable: true,
      okText: 'Save',
      onOk: onOk,
      title: projectDetail?.id ? 'Update Project' : 'Create Project',
      mode: 'form',
      maskClosable: false,
      formConfig: {
        type: 'Page',
        elements: [
          {
            type: 'Input',
            label: 'Project Name',
            name: 'name',
            fullRow: true,
            initialValue: projectDetail?.name,
            disabled:
              projectDetail?.name && !userInfo.permissions.includes('Admin')
                ? true
                : false,
          },
          {
            type: 'Select',
            label: 'Project Manager',
            name: 'productManagers',
            showSearch: true,
            multiple: true,
            options: pmList.map((email) => ({
              label: email,
              value: email,
            })),
            fullRow: true,
            initialValue: projectDetail?.productManagers,
          },
          {
            type: 'Select',
            label: 'Front-End Tech',
            name: 'frontEngineers',
            showSearch: true,
            multiple: true,
            options: feList.map((email) => ({
              label: email,
              value: email,
            })),
            fullRow: true,
            initialValue: projectDetail?.frontEngineers,
          },
          {
            type: 'Select',
            label: 'Multi-lingual Language',
            name: 'languages',
            multiple: true,
            options: languageList.map(({ name }) => ({
              label: name,
              value: name,
            })),
            fullRow: true,
            initialValue: projectDetail?.languages,
          },
          {
            required: false,
            type: 'TextArea',
            label: 'Project Multi-lingual Context Reference',
            name: 'contextReference',
            maxLength: 0,
            fullRow: true,
            initialValue: projectDetail?.contextReference,
          },
        ],
      },
    });
  }, [projectDetail, feList, pmList, languageList]);

  return showModal;
}
