import { request as umiReqeust } from 'umi';

export let request = umiReqeust;

const getOptions = (options: any) => {
  if (!options) {
    return {
      headers: {
        'X-Axinan-Authorization': localStorage.getItem(TOKEN_NAME) as string,
      },
    };
  }

  if (options.headers) {
    return {
      ...options,
      headers: {
        ...options.headers,
        'X-Axinan-Authorization': localStorage.getItem(TOKEN_NAME) as string,
      },
    };
  }

  return {
    ...options,
    headers: {
      'X-Axinan-Authorization': localStorage.getItem(TOKEN_NAME) as string,
    },
  };
};

export const updateRequest = () => {
  request = (url: string, options?: any) =>
    umiReqeust(url, getOptions(options));
};
