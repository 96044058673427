export function getEmail() {
  const token = localStorage.getItem(TOKEN_NAME);
  let msg = null;

  try {
    const base64Url = token?.split('.')[1];
    const base64 = base64Url?.replace('-', '+').replace('_', '/');
    msg = JSON.parse(window.atob(base64 as string));
  } catch (error) {
    msg = {};
  }

  return msg.email;
}
